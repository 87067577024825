
if ("serviceWorker" in navigator) {

    navigator.serviceWorker.register(new URL("./sw-run.js", import.meta.url))
    .then(reg =>{
        // console.log(reg);
    })
    .catch(()=>{
        console.log("Error adding service worker");
    })

}